import React from "react";
import Select from "react-select";
import {Container, Grid} from "semantic-ui-react";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import FreeviewProvider from "../../../Services/FreeviewDataProvider";
import ContingentButton from "../../ContingentButton";

class FreeviewMdMasterDetailView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            metadata: {},
            selectedAttr: ""
        };

        FreeviewProvider.init({baseURL: process.env.FREEVIEW_MD_URL});

        this.getConfigMetadata();
    }

    componentWillUnmount() {
        if (this.jsoneditor) {
            this.jsoneditor.destroy();
        }
    }

    componentDidMount() {
        this.getConfigMetadata();
        const options = {
            mode: "text"
        };
        this.jsoneditor = new JSONEditor(this.container, options);
    }

    getConfigMetadata = () => {
        console.log("(FreeviewMdMasterDetailView.getConfigMetadata) Started.");
        FreeviewProvider.getConfigMetadata().then(payload => {
            payload.json().then(metadataPayload => {
                console.log(`(FreeviewMdMasterDetailView.getConfigMetadata) Metadata: ${metadataPayload}`);
                this.setState({
                    metadata: metadataPayload
                });
            });
        });
    };

    filterOnPermissions = () => {
        console.log("(FreeviewMdMasterDetailView.filterOnPermissions) Started");
        let filteredList = [];

        try {
            console.log("(FreeviewMdMasterDetailView.filterOnPermissions) Master permission detected");
            Object.keys(this.state.metadata).sort().forEach(data => {
                filteredList.push({
                    value: data,
                    label: data
                });
            });

        } catch (error) {
            this.props.toast("Message", error.toString(), "error");
        }

        return filteredList;
    };


    handleReactSelect = selectData => {
        console.log(`(FreeviewMdMasterDetailView.handleReactSelect) Started, selectData: ${JSON.stringify(selectData)}`);
        let metadataAttr = selectData.value;
        let attrData = "";

        try {
            attrData = this.state.metadata[metadataAttr];
        } catch (error) {
            attrData = "";
        }

        this.setState({
            selectedAttr: selectData
        });

        this.jsoneditor.set(attrData);
    };

    saveMetadata = () => {
        console.log("(FreeviewMdMasterDetailView.saveMetadata) Started.");
        try {
            let attrData = this.jsoneditor.get();
            let selectedAttr = this.state.selectedAttr.value;
            let metadata = {};
            metadata[selectedAttr] = attrData;
            console.log(`(FreeviewMdMasterDetailView.saveMetadata) metadata: ${JSON.stringify(metadata)}`);
            FreeviewProvider.setConfigMetadata(metadata).then(payload => {
                payload.json().then(json => {
                    this.props.toast("Message", "Freeview MD updated: success", "success");
                });
            });
        } catch (error) {
            console.log(`(FreeviewMdMasterDetailView.saveMetadata) Error saving metadata: ${error.toString()}`);
            this.props.toast("Error", error.toString(), "error");
        }
    };

    render() {
        return (
            <Grid>
                <Grid.Column className="masterContainer">
                    <Grid.Row floated="left">
                        <br/>
                    </Grid.Row>
                    {
                        this.state.metadata &&
                        <Grid.Row style={{paddingBottom: "3ch"}}>
                            <h4>Select Attribute:</h4>
                            <Select
                                value={this.state.selectedAttr}
                                onChange={this.handleReactSelect}
                                options={this.filterOnPermissions()}/>
                        </Grid.Row>
                    }

                    <Container style={{minHeight: "calc(100vh-40px)"}} fluid>
                        <Grid.Row>
                            <h4>Attribute Data:</h4>
                            <div style={{height: "62vh"}} ref={elem => this.container = elem}/>
                        </Grid.Row>
                        {
                            this.state.selectedAttr &&
                            <Grid.Row style={{paddingTop: "3ch"}}>
                                <ContingentButton
                                    onClick={this.saveMetadata}
                                    service={this.props.service}
                                    module={this.props.module}
                                    scope="all"
                                    user={this.props.user}
                                    allPermissions={this.props.permissions}
                                >Save Metadata</ContingentButton>
                            </Grid.Row>
                        }
                    </Container>
                </Grid.Column>
            </Grid>
        );
    }
}

export default FreeviewMdMasterDetailView;
