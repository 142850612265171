import React, {Component} from "react";
import {Tab} from "semantic-ui-react";
import SecureTabPane from "../../SecureTabPane";
import FreeviewMetadataDetailView from "./FreeviewMetadataDetailView";
import FreeviewMdMasterDetailView from "./FreeviewMdMasterDetailView";
export default class FreeviewMasterDetailView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Tab
                panes={[
                    {
                        menuItem: {
                            key: "freeview-metadata-tab",
                            content: <label>Metadata</label>
                        },
                        render: () =>
                            <SecureTabPane
                                user={this.props.user}
                                authenticated={this.props.authenticated}
                                checkIfAuthorized={this.props.checkIfAuthorized}
                                permissionsRequired={["urn:aspen:power"]}
                                component={<FreeviewMetadataDetailView  {...this.props} />}
                                module={this.props.module}
                                permissions={this.props.permissions}
                                service={this.props.service}
                                userPermissions={this.props.userPermissions}
                                loadingPermissions={this.props.loadingPermissions}
                            />
                    },
                    {
                        menuItem: {
                            key: "freeview-config-selector-tab",
                            content: <label>Config</label>
                        },
                        render: () =>
                            <SecureTabPane
                                user={this.props.user}
                                authenticated={this.props.authenticated}
                                checkIfAuthorized={this.props.checkIfAuthorized}
                                permissionsRequired={["urn:guest:guest"]}
                                component={<FreeviewMdMasterDetailView {...this.props} />}
                                module={this.props.module}
                                permissions={this.props.permissions}
                                service={this.props.service}
                                role="aspen-power"
                                userPermissions={this.props.userPermissions}
                                loadingPermissions={this.props.loadingPermissions}
                            />
                    }
                ]}
            />
        );
    }
}
