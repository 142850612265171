import React from "react";
import ReactTable from "react-table-v6";
import {Grid, Icon, Message} from "semantic-ui-react";
import FreeviewProvider from "../../../Services/FreeviewDataProvider";
import FreeviewDetailPage from "./FreeviewDetailPage";
import ContingentButton from "../../ContingentButton";

FreeviewProvider.init({baseURL: process.env.FREEVIEW_MD_URL});

class FreeviewMetadataDetailView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newClicked: false,
            selectedKey: "",
            selectedApp: {},
            loading: true,
            apps: []
        };

        this.getAllMetadata();
    }

    getAllMetadata = () => {
        FreeviewProvider.getAllMetadata().then(payload => {
            payload.json().then(apps => {
                let transformedApps = apps.map(app => {
                    return {
                        _id: app._id,
                        appId: app.appId,
                        hashAppId: (app.hashAppId !== null && app.hashAppId !== undefined)? app.hashAppId : true,
                        ipCheck: app.ipCheck? app.ipCheck : false,
                        hashText: app.hashAppId ? "Enabled" : "Disabled",
                        ipCheckText: app.ipCheck ? "Enabled" : "Disabled",
                        fields: app.fields,
                        repeatInterval: app.repeatInterval,
                        entitledNetworks: app.entitledNetworks,
                        lastResetTime: app.lastResetTime,
                        resetOffset: app.resetOffset ? app.resetOffset : 0,
                        secret: app.secret ? app.secret : "",
                        tokenValue: ("wc" in app) ? "wc" : ("sw" in app) ? "sw" : "",
                        ttl: app.ttl ? app.ttl : 0,
                        updateFrequency: app.updateFrequency ? app.updateFrequency : 30,
                        tokenType: ("wc" in app) ? "wallclock" : ("sw" in app) ? "stopwatch" : "",
                        tokenTypeTime: ("wc" in app) ? app.wc : ("sw" in app) ? app.sw : 0
                    };
                });
                let updatedSelectedApp = Object.assign({}, transformedApps.find(app => app._id === this.state.selectedKey));
                this.setState({
                    apps: transformedApps,
                    selectedApp: updatedSelectedApp,
                    loading: false
                });
            });
        });
    };

    handleSelectApp = app => {
        this.setState({
            selectedApp: app,
            newClicked: false
        });
    };

    newMetadataClicked = () => {
        this.setState({
            selectedApp: {},
            newClicked: true
        });
    };

    render() {
        let that = this;
        return (
            <Grid style={{height: "100vh"}}>
                <Grid.Column width={10} className="masterContainer">
                    <ContingentButton
                        align
                        floated="right"
                        onClick={this.newMetadataClicked}
                        service={this.props.service}
                        module={this.props.module}
                        scope="all"
                        allPermissions={this.props.permissions}
                        user={this.props.user}
                    >Add New Metadata</ContingentButton>
                    <br/>
                    <br/>
                    <br/>
                    <ReactTable
                        columns={[
                            { Header: "App ID", accessor: "appId" },
                            { Header: "Token Type", accessor: "tokenType" },
                            { Header: "Token Time", accessor: "tokenTypeTime" },
                            { Header: "TTL", accessor: "ttl" },
                            { Header: "Hash App ID", accessor: "hashAppId",
                                Cell: row => <span><Icon name={row.value ? "check" : "x"} /></span>
                            }
                        ]}
                        data={this.state.apps}
                        loading={this.state.loading}
                        filterable
                        defaultPageSize={50}
                        defaultSorted={[
                            {
                                id: "appId",
                                desc: false
                            }
                        ]}
                        defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                        className="-striped -highlight"
                        getTrProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick(event, handleOriginal) {
                                    that.setState({selectedKey: rowInfo.original._id });
                                    that.handleSelectApp(rowInfo.original);
                                },
                                style: {
                                    background: rowInfo && rowInfo.original && rowInfo.original._id && that.state.selectedKey === rowInfo.original._id ? "rgba(65, 83, 175, .5)" : "",
                                }
                            }
                        }}
                        style={{height: "75vh"}}
                    />
                </Grid.Column>
                <Grid.Column width={6} className="detailsContainer">
                    {
                        this.state.selectedKey.length < 1 && !this.state.newClicked ?
                            <Message icon color="yellow">
                                <Icon name="info" />
                                <Message.Content>Please select an app to continue, or add new metadata.</Message.Content>
                            </Message> :
                            <FreeviewDetailPage
                                toast={this.props.toast}
                                disableAppId={!this.state.newClicked}
                                onUpdateMetadata={this.getAllMetadata}
                                app={this.state.selectedApp}
                                service={this.props.service}
                                module={this.props.module}
                                permissions={this.props.permissions}
                                user={this.props.user}
                            />
                    }
                </Grid.Column>
            </Grid>
        );
    }
}

export default FreeviewMetadataDetailView;
