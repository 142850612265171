import React, {useState, useEffect, useRef} from "react";
import EventDispatcherDataProvider from "../../../Services/EventDispatcherDataProvider";
import {Button, Form, Grid, Input} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import { JsonEditor as Editor } from 'jsoneditor-react';
import "jsoneditor/dist/jsoneditor.css";

export default function CallbackEditor(props) {
    const [callbacks, setCallbacks] = useState([]);
    
    const [selectedEventType, setSelectedEventType] = useState("");
    const [selectedAppId, setSelectedAppId] = useState("");
    const [selectedBrands, setSelectedBrands] = useState("");
    const [selectedUrl, setSelectedUrl] = useState("");
    const [selectedMaxAttempts, setSelectedMaxAttempts] = useState(0);
    const [selectedRetryMin, setSelectedRetryMin] = useState(0);
    const [selectedConnTimeout, setSelectedConnTimeout] = useState(0);
    const [selectedReadTimeout, setSelectedReadTimeout] = useState(0);
    const [selectedProviderId, setSelectedProviderId] = useState("");
    const [selectedParameters, setSelectedParameters] = useState({});
    const [enableSaveButton, setEnableSaveButton] = useState(true);
    const [enableDeleteButton, setEnableDeleteButton] = useState(true);

    EventDispatcherDataProvider.init(process.env.EVENT_DISPATCHER_URL);
    let parameterJsonEditor = null;

    useEffect(() => {
        getCallbacks();
    }, []);
    
    useEffect(() => {
        if(selectedAppId && selectedEventType) {
            setEnableDeleteButton(false);
        } else {
            setEnableDeleteButton(true);
        }
    }, [selectedAppId, selectedEventType])

    useEffect(() => {
        if(selectedAppId && 
            selectedEventType && 
            selectedUrl && 
            selectedMaxAttempts && 
            selectedRetryMin && 
            selectedConnTimeout && 
            selectedReadTimeout &&
            selectedParameters) {
            setEnableSaveButton(false);
        } else {
            setEnableSaveButton(true);
        }
    }, [selectedAppId, selectedEventType, selectedUrl, selectedMaxAttempts, selectedRetryMin, selectedConnTimeout, selectedReadTimeout, JSON.stringify(selectedParameters)])

    const jsonEditorRef = (instance) => {
        if(instance && instance.jsonEditor !== null) {
            parameterJsonEditor = instance.jsonEditor;
        } else {
            parameterJsonEditor = null;
        }
    }

    const getCallbacks = () => {
        EventDispatcherDataProvider.getCallbacks().then(response => {
            setCallbacks(response.callbacks ? response.callbacks: []);
        });
    };

    const saveCallback = () => {
        let saveParams = {
            "eventType": selectedEventType,
            "destinationAppId": selectedAppId,
            "url": selectedUrl,
            "brands": selectedBrands.split(","), 
            "maxNumAttempts": selectedMaxAttempts,
            "retryWaitMinutes": selectedRetryMin,
            "connectTimeoutSeconds": selectedConnTimeout,
            "readTimeoutSeconds": selectedReadTimeout,
            "authorizationHeaderProviderParameters": {
                "authorizationHeaderProviderId": selectedProviderId,
                "parameters": selectedParameters
            }
        }
        

        EventDispatcherDataProvider.saveCallback(saveParams).then(response => {
            console.log(response)
            if(response.ok) {
                props.toast("success", "Callback Saved");
                getCallbacks();
            } else {
                props.toast("Error", "Error Occured Saving Callback", "error");
            }
            
        })
    }

    const deleteCallback = () => {
        EventDispatcherDataProvider.deleteCallback(selectedAppId, selectedEventType).then(response => {
            
            if(response.ok) {
                props.toast("success", "Callback Deleted");
                getCallbacks();
            } else {
                props.toast("Error", "Error Occured Deleting Callback", "error");
            }
        })
    }

    return(<Grid>
        <Grid.Column width={10} className="tableContainer">
            <Grid.Column width={16}>
                <ReactTable
                    data={callbacks}
                    columns={[
                        {Header: "Event Type", accessor: "eventType"},
                        {Header: "App ID", accessor: "destinationAppId"},
                        {id: "brands", Header: "Brands", accessor:(row) => {
                            return row.brands.toString()
                        }},
                        {Header: "Url", accessor: "url"},
                        {id:"maxNumAttempts", Header: "Max Attempts", accessor: (row) => row.maxNumAttempts || 0},
                        {id:"retryWaitMinutes", Header: "Retry Minutes", accessor: (row) => row.retryWaitMinutes || 0},
                        {id:"connectTimeoutSeconds", Header: "Conn Timout", accessor: (row) => row.connectTimeoutSeconds || 0},
                        {id: "readTimeoutSeconds", Header: "Read Timeout", accessor: (row) => row.readTimeoutSeconds || 0},
                        {Header: "Provider ID", accessor: "authorizationHeaderProviderParameters.authorizationHeaderProviderId"},
                        {Header: "Parameters", accessor: "authorizationHeaderProviderParameters.parameters", show:false},
                    ]}
                    filterable
                    getTrProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick(event, handleOriginal) {
                                setSelectedEventType(rowInfo.row.eventType)
                                setSelectedAppId(rowInfo.row.destinationAppId)
                                setSelectedBrands(rowInfo.row.brands)
                                setSelectedUrl(rowInfo.row.url)
                                setSelectedMaxAttempts(rowInfo.row.maxNumAttempts)
                                setSelectedRetryMin(rowInfo.row.retryWaitMinutes)
                                setSelectedConnTimeout(rowInfo.row.connectTimeoutSeconds)
                                setSelectedReadTimeout(rowInfo.row.readTimeoutSeconds)
                                if(rowInfo.row["authorizationHeaderProviderParameters.authorizationHeaderProviderId"]) {
                                    setSelectedProviderId(rowInfo.row["authorizationHeaderProviderParameters.authorizationHeaderProviderId"])
                                } else {
                                    setSelectedProviderId("");
                                }

                                if(rowInfo.row["authorizationHeaderProviderParameters.parameters"]) {
                                    setSelectedParameters(rowInfo.row["authorizationHeaderProviderParameters.parameters"])
                                    if(parameterJsonEditor !== null) {
                                        parameterJsonEditor.set(rowInfo.row["authorizationHeaderProviderParameters.parameters"])
                                    }
                                } else {
                                    setSelectedParameters({});
                                    parameterJsonEditor.set({})
                                }

                            },
                            style: {
                                background: rowInfo && rowInfo.row && (selectedEventType + selectedAppId) === (rowInfo.row.eventType + rowInfo.row.destinationAppId)? "rgba(65, 83, 175, .5)" : ""
                            }
                        }
                    }}
                />
            </Grid.Column>
        </Grid.Column>
        <Grid.Column width={6} className="EditorContainer">
                <Form>
                    <Form.Group>
                        <Button onClick={saveCallback} disabled={enableSaveButton}>Save Callback</Button>
                        <Button onClick={deleteCallback} disabled={enableDeleteButton}>Delete Callback</Button>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            fluid
                            label="Event Type"
                            control={Input}
                            value={selectedEventType}
                            onChange={(event, {value}) => setSelectedEventType(value)}
                        />
                        <Form.Field
                            fluid
                            label="Brands"
                            control={Input}
                            value={selectedBrands}
                            onChange={(event, {value}) => setSelectedBrands(value)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            fluid
                            label="Destination App ID"
                            control={Input}
                            value={selectedAppId}
                            onChange={(event, {value}) => setSelectedAppId(value)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            fluid
                            label="URL"
                            control={Input}
                            value={selectedUrl}
                            onChange={(event, {value}) => setSelectedUrl(value)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            fluid
                            label="Max Attempts"
                            control={Input}
                            value={selectedMaxAttempts}
                            onChange={(event, {value}) => setSelectedMaxAttempts(value)}
                        />
                        <Form.Field
                            fluid
                            label="Retry Minutes"
                            control={Input}
                            value={selectedRetryMin}
                            onChange={(event, {value}) => setSelectedRetryMin(value)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            fluid
                            label="Connection Timeout"
                            control={Input}
                            value={selectedConnTimeout}
                            onChange={(event, {value}) => setSelectedConnTimeout(value)}
                        />
                        <Form.Field
                            fluid
                            label="Read Timeout"
                            control={Input}
                            value={selectedReadTimeout}
                            onChange={(event, {value}) => setSelectedReadTimeout(value)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            fluid
                            label="Authorization Header Provider Id"
                            control={Input}
                            value={selectedProviderId}
                            onChange={(event, {value}) => setSelectedProviderId(value)}
                        />
                    </Form.Group>
                    <Editor ref={jsonEditorRef} value={selectedParameters} onChange={setSelectedParameters} mode="text" />
                </Form>
        </Grid.Column>
    </Grid>)
}