import React from "react";
import FreeviewProvider from "../../../Services/FreeviewDataProvider";
import {Button, Container, Form, Grid, GridRow, Icon, Input, Message, Select} from "semantic-ui-react";
import ContingentButton from "../../ContingentButton";


class FreeviewTokenDetailView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingAppIds: true,
            loadingData: false,
            savingData: false,
            selectedApp: {},
            appId: "",
            fingerprint: "",
            tag:"",
            _id:"",
            addedTime: 0,
            tokenTypeTime: 0,
            apps: [],
            values: [],
            displayJson: {},
            appIdDropdown: [],
            tagMap: {},
            tokenType: "",
            fields: []
        };
        FreeviewProvider.init({baseURL: process.env.FREEVIEW_RESET_URL});

        this.getAllMetadata();
    }

    getAllMetadata = () => {
        FreeviewProvider.getAllPublicMetadata().then(payload => {
            payload.json().then(apps => {
                let transformedApps = apps.map(app => {
                    return {
                        _id: app._id,
                        appId:app.appId,
                        degraded: app.degraded,
                        degradedText: app.degraded ? "Enabled" : "Disabled",
                        ipCheckText: app.ipCheck ? "Enabled" : "Disabled",
                        fields: app.fields,
                        tag: app.tag ? app.tag: "",
                        lastResetTime: app.lastResetTime,
                        resetOffset: app.resetOffset ? app.resetOffset : 0,
                        secret: app.secret ? app.secret : "",
                        tokenValue: ("wc" in app) ? "wc" : ("sw" in app) ? "sw" : "",
                        ttl: app.ttl ? app.ttl : 0,
                        updateFrequency: app.updateFrequency ? app.updateFrequency : 30,
                        tokenType: ("wc" in app) ? "wallclock" : ("sw" in app) ? "stopwatch" : "",
                        tokenTypeTime: ("wc" in app) ? app.wc : ("sw" in app) ? app.sw : 0
                    };
                });

                let dropdownValues = [];
                transformedApps.sort((a,b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0));
                let uniqueAppIds = new Set();
                let tagMap = {};
                for (let key in transformedApps) {
                    let app = transformedApps[key];
                    uniqueAppIds.add(app.appId)
                    if (app.tag) {
                        if (tagMap.hasOwnProperty(app.appId)) {
                            let tagList = tagMap[app.appId];
                            tagList.push({
                                key: app.tag,
                                text: app.tag,
                                value: app.tag
                            });
                        } else {
                            let tagList = [];
                            tagList.push({
                                key: app.tag,
                                text: app.tag,
                                value: app.tag
                            });
                            tagMap[app.appId] = tagList;
                        }
                    }
                }
                uniqueAppIds.forEach(id => {
                    dropdownValues.push({
                        key: id,
                        text: id,
                        value: id
                    });
                });

                this.setState({
                    apps: transformedApps,
                    tagMap: tagMap,
                    appIdDropdown: dropdownValues,
                    loadingAppIds: false
                });
            });
        });
    };

    getTimeToken = () => {
        let payload = Object.assign({}, this.state);
        this.setState({loadingData: true}, () => {
            FreeviewProvider.getTokenTime(this.prepareTokenPayload(payload)).then(resp => {
                resp.json().then(payload => {
                    if ("elapsedTime" in payload) {
                        this.setState({
                            displayJson: payload
                        });
                        return;
                    }

                    if ("freeviewStartTime" in payload) {
                        let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                        d.setUTCSeconds(payload.freeviewStartTime / 1000);
                        payload.freeviewStartTime = d.toLocaleString();
                        this.setState({
                            displayJson: payload
                        });
                        return;
                    }

                    this.setState({displayJson: payload});
                }).catch(error => {
                    this.props.toast("Getting Payload failed");
                    this.setState({displayJson: error});
                    console.log(error);
                });
            }).finally(() => {
                this.setState({loadingData: false});
            });
        });
    };

    handleDynamicChange = index => (event) => {
        let values = [...this.state.values];
        values[index] = event.target.value;
        let field = {};
        field[event.target.id] = values[index];
        this.setState(field);
        this.setState({values: values});
    }

    setTimeToken = () => {
        let payload = Object.assign({}, this.state);
        let givenTime = parseInt(this.state.addedTime) * 1000;
        if (this.state.tokenType === "sw") {
            payload.elapsedTime = givenTime;
        } else if (this.state.tokenType === "wc") {
            payload.freeviewStartTime = Date.now() - (this.state.tokenTypeTime - givenTime);
        } else {
            this.props.toast("Please make sure to select an App Id");
            return;
        }

        this.setState({savingData: true}, () => {
            FreeviewProvider.setTokenTime(this.prepareTokenPayload(payload)).then(resp => {
                resp.json().then(payload => {
                    this.setState({
                        displayJson: payload
                    });
                }).catch(error => {
                    this.props.toast("Getting Payload failed");
                    console.log(error);
                });
            }).finally(() => {
                this.setState({savingData: false});
            });
        });
    };

    resetTimeToken = () => {
        let payload = Object.assign({}, this.state)
        payload.elapsedTime = 0;
        payload.freeviewStartTime = Date.now();

        this.setState({savingData: true}, () => {
            FreeviewProvider.setTokenTime(this.prepareTokenPayload(payload)).then(resp => {
                resp.json().then(payload => {
                    this.setState({displayJson: payload});
                }).catch(error => {
                    this.props.toast("Getting Payload failed");
                    console.log(error);
                })
            }).finally(() => {
                this.setState({savingData: false});
            });
        });
    };

    handleChange = id => event => {
        let text = event.target.value;
        if (id==="tag") {
            this.setState({[id]: event.target.innerText});
        } else {
            this.setState({[id]: text});
        }
    };

    prepareTokenPayload = json => {
        delete json.selectedApp;
        delete json.apps;
        delete json.values;
        delete json.displayJson;
        delete json.appIdDropdown;
        delete json.displayJson;
        return json;
    }

    updateSelectedAppId = (event, {value}) => {
        let selectedApp = this.state.apps.filter(app => app.appId === value)[0];
        console.log(value);
        console.log(selectedApp);
        this.setState({
            appId: value,
            selectedApp: selectedApp,
            tokenType: selectedApp.tokenValue,
            tokenTypeTime: selectedApp.tokenTypeTime
        });
        this.render();
    };

    render() {
        let displayJsonObj = this.state.displayJson;
        return (
            <Grid style={{height: "100vh"}}>
                <GridRow>
                <Grid.Column width={9} className="masterContainer">
                    {
                        this.state.loadingAppIds ?
                            <Message color="yellow" icon>
                                <Icon name="spinner" loading />
                                <Message.Content>Loading App IDs...</Message.Content>
                            </Message> :
                            <Form>
                                <Form.Field
                                    label="App IDs"
                                    control={Select}
                                    placeholder="App IDs"
                                    value={this.state.appId}
                                    options={this.state.appIdDropdown}
                                    onChange={this.updateSelectedAppId}
                                />
                                <br/>
                                {
                                    (!this.state.selectedApp || !this.state.tagMap.hasOwnProperty(this.state.selectedApp.appId)) ? null :
                                        <Form.Field
                                            label="Tags"
                                            control={Select}
                                            placeholder="Tags"
                                            onChange={this.handleChange("tag")}
                                            value={this.state.tag}
                                            options={this.state.tagMap[this.state.selectedApp.appId]}
                                        />
                                }
                                <Form.Field>
                                    <label>Fingerprint</label>
                                    <Input
                                        id="fingerprint"
                                        name="fingerprint"
                                        value={this.state.fingerprint}
                                        onChange={this.handleChange("fingerprint")}
                                    />
                                </Form.Field>
                                <br/>
                                {this.state.selectedApp ?
                                    <Form.Field>
                                        <label>{this.state.selectedApp.tokenValue === "sw" ? "Set Time Elapsed (In Seconds)" : "Set Time Remaining (In Seconds)"}</label>
                                        <Input
                                            id="addedTime"
                                            input="number"
                                            value={this.state.addedTime}
                                            onChange={this.handleChange("addedTime")}
                                        />
                                    </Form.Field>
                                    : null
                                }
                                < br/>
                                {
                                    (!this.state.selectedApp || !this.state.selectedApp.fields) ? null : this.state.selectedApp.fields.map((field, i) => (
                                        <Form.Field key={`form-field-${i}`}>
                                            <label>{field}</label>
                                            <input id={field}
                                                   value={this.state.values[i] || ""}
                                                   onChange={this.handleDynamicChange(i)}
                                            />
                                            <br/>
                                            <br/>
                                        </Form.Field>


                                    ))
                                }
                                <br/>
                                <Button.Group size="medium">
                                    <Button
                                        color="light blue"
                                        onClick={this.getTimeToken}
                                    >Get Token Time</Button>
                                    <Button.Or/>
                                    <ContingentButton
                                        service={this.props.service}
                                        module={this.props.module}
                                        scope="any"
                                        allPermissions={this.props.permissions}
                                        user={this.props.user}
                                        onClick={this.setTimeToken}
                                    >Set Token Time</ContingentButton>
                                    <Button.Or/>
                                    <ContingentButton
                                        service={this.props.service}
                                        module={this.props.module}
                                        scope="any"
                                        allPermissions={this.props.permissions}
                                        user={this.props.user}
                                        onClick={this.resetTimeToken}
                                        color="light blue"
                                    >Reset Token Time</ContingentButton>
                                </Button.Group>
                                <br/>
                            </Form>
                    }
                </Grid.Column>
                <Grid.Column width={7} style={{backgroundColor: "#EAEAEA"}}>
                    <Container>
                        {
                            this.state.loadingData ?
                                <Message color="blue" icon>
                                    <Icon name="spinner" loading/>
                                    <Message.Content>Getting Token Time...</Message.Content>
                                </Message> :
                                this.state.savingData ?
                                    <Message color="blue" icon>
                                        <Icon name="spinner" loading/>
                                        <Message.Content>
                                            Updating Token Time...
                                        </Message.Content>
                                    </Message> :
                                    <div className="json-block-light">
                                        <div style={{paddingBottom: "20px"}} />
                                        <pre dangerouslySetInnerHTML={{__html: syntaxHighlight(JSON.stringify(displayJsonObj, null, 2))}}/>
                                    </div>
                        }
                    </Container>
                </Grid.Column>
                </GridRow>
            </Grid>
        );
    }
}

function syntaxHighlight(json) {
    json = json.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        let cls = "number";
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = "key";
            } else {
                cls = "string";
            }
        } else if (/true|false/.test(match)) {
            cls = "boolean";
        } else if (/null/.test(match)) {
            cls = "null";
        }
        return `<span class="${cls}">${match}</span>`;
    });
}

export default FreeviewTokenDetailView;
