import React, {useEffect, useState} from "react";
import {Form, Checkbox, Input, Select, TextArea} from "semantic-ui-react";
import FreeviewProvider from "../../../Services/FreeviewDataProvider";
import PasswordInput from "../../PasswordInput";
import ContingentButton from "../../ContingentButton";

const TIME_OPTIONS = [
    {key: "wc", text: "Wallclock", value: "wc"},
    {key: "sw", text: "Stopwatch", value: "sw"}
];

const REPEAT_INTERVAL_DROPDOWN = [
    {key: "daily", text: "Daily", value: "daily"},
    {key: "monthly", text: "Monthly", value: "monthly"},
    {key: "yearly", text: "Yearly", value: "yearly"}
];

export default function FreeviewDetailPage(props) {
    const [updateFrequency, setUpdateFrequency] = useState(0);
    const [fields, setFields] = useState([]);
    const [entitledNetworks, setEntitledNetworks] = useState([]);
    const [ttl, setTtl] = useState(0);
    const [hashText, setHashText] = useState("enabled");
    const [hashAppId, setHashAppId] = useState(false);
    const [resetOffset, setResetOffset] = useState(0);
    const [secret, setSecret] = useState("");
    const [ipCheck, setIpCheck] = useState(false);
    const [ipCheckText, setIpCheckText] = useState("Disabled");
    const [repeatInterval, setRepeatInterval] = useState("daily");
    const [tokenValue, setTokenValue] = useState("wc");
    const [tokenTypeTime, setTokenTypeTime] = useState(0);
    const [lastResetTime, setLastResetTime] = useState(0);
    const [appId, setAppId] = useState("");
    const [id, setId] = useState("");

    useEffect(function () {
        FreeviewProvider.init({baseURL: process.env.FREEVIEW_MD_URL});
    }, []);

    useEffect(() => {
        setAppId(props.app?.appId ?? "");
        setId(props.app?._id ?? "");
        setLastResetTime(props.app?.lastResetTime ?? 0);
        setSecret(props.app?.secret ?? "");
        setResetOffset(props.app?.resetOffset ?? 0);
        setHashAppId(props.app?.hashAppId ?? false);
        setIpCheck(props.app?.ipCheck ?? false);
        setRepeatInterval(props.app?.repeatInterval ?? 0);
        setHashText(props.app?.hashText ?? "enabled");
        setIpCheckText(props.app?.ipCheckText ?? "Disabled");
        setFields(props.app?.fields ?? []);
        setEntitledNetworks(props.app?.entitledNetworks ?? []);
        setTokenValue(props.app?.tokenValue ?? "wc");
        setTokenTypeTime(props.app?.tokenTypeTime ?? 0);
        setTtl(props.app?.ttl ?? 0);
        setUpdateFrequency(props.app?.updateFrequency ?? 0);
    }, [JSON.stringify(props.app)]);

    const updateMetadata = () => {
        if (!appId || !secret) {
            props.toast("Message", "You must provide an app id and a secret", "error");
            return;
        }

        const fieldsToSend = Array.isArray(fields) ? fields : fields.split(",");
        const entitledNetworksToSend = Array.isArray(entitledNetworks) ? entitledNetworks : entitledNetworks.split(",");

        FreeviewProvider.setMetadata(appId, tokenTypeTime, lastResetTime, resetOffset, hashAppId, fieldsToSend, tokenValue, ttl, secret, updateFrequency, entitledNetworksToSend, repeatInterval, ipCheck).then(resp => {
            resp.json().then(payload => {
                if (payload.message) {
                    props.toast("Message", payload.message, "success");

                } else if (payload.error) {
                    props.toast("Message", payload.error, "error");
                }

                props.onUpdateMetadata();
            });
        });
    };

    return (
        <Form>
            <Form.Field>
                <label>ID</label>
                <Input
                    value={id}
                    readOnly={true}
                    type="text"
                    onChange={(event, {value}) => setId(value)}
                    placeholder="App ID"
                    label={{icon: "lock"}}
                    labelPosition="left corner"
                />
            </Form.Field>
            <Form.Field>
                <label>App ID</label>
                <Input
                    value={appId}
                    readOnly={props.disableAppId}
                    type="text"
                    onChange={(event, {value}) => setAppId(value)}
                    label={{icon: props.disableAppId ? "lock" : "lock open"}}
                    labelPosition="left corner"
                />
            </Form.Field>
            <Form.Field
                label="Time Method"
                control={Select}
                options={TIME_OPTIONS}
                value={tokenValue}
                onChange={(event, {value}) => setTokenValue(value)}
            />
            <Form.Field
                label="Repeat Interval"
                control={Select}
                options={REPEAT_INTERVAL_DROPDOWN}
                value={repeatInterval}
                onChange={(event, {value}) => setRepeatInterval(value)}
            />
            <Form.Field
                label="Token Time in Milliseconds"
                control={Input}
                type="number"
                value={tokenTypeTime}
                onChange={(event, {value}) => setTokenTypeTime(value)}
                placeholder="Token Time"
            />
            <Form.Field
                label="Last Time Reset in Milliseconds"
                control={Input}
                type="number"
                value={lastResetTime}
                onChange={(event, {value}) => setLastResetTime(value)}
                placeholder="Last Reset Time"
            />
            <Form.Field
                label="Secret"
                control={PasswordInput}
                value={secret}
                placeholder="Secret"
                onChange={(event, {value}) => setSecret(value)}
            />
            <Form.Field
                label="Reset Offset in Seconds"
                control={Input}
                type="number"
                value={resetOffset}
                onChange={(event, {value}) => setResetOffset(value)}
                placeholder="Reset Offset"
            />
            <Form.Group widths="equal">
                <Form.Field
                    label="Hash App ID"
                    control={Checkbox}
                    value={hashAppId}
                    checked={hashAppId}
                    onChange={(event, {checked}) => setHashAppId(checked)}
                />
                <Form.Field
                    label="Additional IP Check"
                    control={Checkbox}
                    value={ipCheck}
                    checked={ipCheck}
                    onChange={(event, {checked}) => setIpCheck(checked)}
                />
            </Form.Group>
            <Form.Field
                label="Required Fields"
                control={TextArea}
                type="text"
                value={fields}
                onChange={(event, {value}) => setFields(value)}
                placeholder="Please Place All Your Required Fields separated by commas"
            />
            <Form.Field
                label="Entitled Networks"
                control={TextArea}
                type="text"
                value={entitledNetworks}
                onChange={(event, {value}) => setEntitledNetworks(value)}
                placeholder="Please Place All Your Entitled Networks separated by commas"
            />
            <Form.Field
                label="TTL in Milliseconds"
                control={Input}
                type="number"
                value={ttl}
                onChange={(event, {value}) => setTtl(value)}
                placeholder="TTL"
            />
            <Form.Field
                label="Update Frequency in Seconds"
                control={Input}
                type="number"
                value={updateFrequency}
                disabled={tokenValue === "wc"}
                onChange={(event, {value}) => setUpdateFrequency(value)}
                placeholder="Update Frequency"
            />
            <br/>
            <br/>
            <ContingentButton
                onClick={updateMetadata}
                service={props.service}
                module={props.module}
                scope="all"
                allPermissions={props.permissions}
                user={props.user}
            >Save</ContingentButton>
        </Form>
    );
};
